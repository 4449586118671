import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/layout'

export default ({ data }) => {
  console.log(data.team.edges[0].node.frontmatter.graphqlKey)
  return (
    <Layout>
      <Container className="team">
        <Row>
          <Col className="top-col page">
            <h1>Meet our team</h1>
          </Col>
        </Row>
        <Row>
          {data.team.edges.map(({ node }, index) => {
            const image = data.teamImages.edges.find(edge => {
              return edge.node.name === `${node.frontmatter.graphqlKey}-circle`
            })
            const fluid = image.node.childImageSharp.fluid
            return (
              <Col className="team-link-col" xs={12} md={4}>
                <Link to={node.fields.slug}>
                  <div className="image-wrapper">
                    <Img alt="" fluid={fluid} />
                    <img
                      className="plus"
                      src={require('../assets/images/plus.png')}
                      alt=""
                    />
                  </div>
                  <h4>{node.frontmatter.name}</h4>
                  <span>{node.frontmatter.position}</span>
                </Link>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    team: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___name }
      filter: { fields: { slug: { regex: "/team/.*/" } } }
    ) {
      edges {
        node {
          frontmatter {
            graphqlKey
            name
            position
          }
          fields {
            slug
          }
        }
      }
    }
    teamImages: allFile(
      sort: { order: ASC, fields: [absolutePath] }
      filter: { relativePath: { regex: "/team/.*.png/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
